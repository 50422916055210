import "./App.css";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  avalanche,
} from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Logo from "./logo.svg";
import { openTransactionModal } from "@xswap-link/sdk";

// RainbowKit config
const config = getDefaultConfig({
  appName: "My RainbowKit App",
  projectId: "YOUR_PROJECT_ID",
  chains: [mainnet, polygon, optimism, arbitrum, base, avalanche],
  ssr: false,
});
const queryClient = new QueryClient();
// XPay logic
export const XPay = () => {
  // XPay configuration responsible for opening modal and building the transactions
  const openXPay = async () => {
    const integratorId = process.env.REACT_APP_INTEGRATOR_ID;
    if (!integratorId) {
      throw new Error("INTEGRATOR_ID is not set in the .env file");
    }

    await openTransactionModal({
      integratorId, // integrator Id from .env
      dstChain: "137", // Base
      dstToken: "0x187ae45f2d361cbce37c6a8622119c91148f261b", // XSWAP Token
    });
  };

  return (
    <div className="app-container">
      <div className="navbar-links">
      <img src={Logo} alt="Polylastic Logo" className="logo" /> {/* Display the logo */}
                <a href="https://polylastic.io/">Polylastic.io</a>
        </div>
      <div className="app-container2">
      <nav className="navbar">
        <ConnectButton />
          <button className="xpay-button" onClick={openXPay}>
            Buy $POLX
          </button>
      </nav>
      <main className="main-content">
        <div className="content">
        </div>
      </main>
      <footer className="footer">
      </footer>
      </div>
    </div>
  );
};

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <XPay />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
